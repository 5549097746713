import axios from "axios";
import { reportError } from "@/utils/error-reporting";

export async function downloadFile(URL, label, ext) {
    let http = await axios.create();

    await http({
        url: URL,
        method: "GET",
        responseType: "blob"
    })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${label}.${ext}`);
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            reportError(error, "Download file failed");
            throw error;
        });

    return;
}
